
const cronometroSesion = (booleano) => {

    if(booleano){
        setTimeout(() => {
            localStorage.removeItem("tokenSession");
            localStorage.removeItem("userSession");
            alert("Sesión expirada, ingrese de nuevo...");
        }, process.env.REACT_APP_TIME_EXPIRE_SESSION)
    }
}
export default cronometroSesion;